body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  color: #fff;
}

.App {
  text-align: center;
}

section {
  padding: 50px 20px;
}

h1, h2, h3 {
  color: #fff;
}
.bg-Image{
  background-image : url("../public/bg-services.png")

}
.bg-Video{
  background-image : url("../public/bannerimg.png");
  background-size: cover;
  background-position: center;
}
.bg1{
  background-image : url("../public/Step1.png")

}
.bg2{
  background-image : url("../public/step2.png")
}

/* button {
  background-color: #ff5722;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #e64a19;
} */
